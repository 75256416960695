import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (renderValueCell, renderActions, renderTypeCell, mudPumpUnit, currentUnit) => [
  {
    field: 'pumpType',
    headerName: 'Pump Type',
    width: 180,
    renderEditCell: renderTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'liner_size',
    headerName: `Liner Size (${mudPumpUnit?.liner_size?.label})`,
    width: 180,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(mudPumpUnit?.liner_size?.decimals);
    },
  },
  {
    field: 'stroke_length',
    headerName: `Stroke Length (${mudPumpUnit?.stroke_length?.label})`,
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(mudPumpUnit?.stroke_length?.decimals);
    },
  },
  {
    field: 'efficiency',
    headerName: `Efficiency (${mudPumpUnit?.efficiency?.label})`,
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(mudPumpUnit?.efficiency?.decimals);
    },
  },
  {
    field: 'bbl_stk',
    headerName: `Output (${mudPumpUnit?.bbl_stk?.label})`,
    width: 200,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(mudPumpUnit?.bbl_stk?.decimals);
    },
  },
  {
    field: 'spm',
    headerName: 'SPM',
    width: 100,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(mudPumpUnit?.spm?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
