import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (renderValueCell, renderNumberValueCell, renderTripTypeCell, renderTripPipeTypeCell, renderActions, strippingUnit, currentUnit) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'type',
    headerName: 'Stripping',
    width: 185,
    renderEditCell: renderTripTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'speed',
    headerName: `Trip Speed (${strippingUnit?.speed?.label})`,
    width: 185,
    renderEditCell: renderNumberValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(strippingUnit?.speed?.decimals));
    },
  },
  {
    field: 'config',
    headerName: 'Tubing',
    width: 180,
    renderEditCell: renderTripPipeTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
