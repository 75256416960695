/* eslint-disable camelcase */
import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import { modifyMetricData } from '../../utils/getMetrics';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const addNewHydraulicsRow = () => (dispatch) => {
  const { hydraulics } = store.getState();
  const newHydraulics = [...hydraulics.data];
  const existUnit = { ...hydraulics.units };
  const nextId = newHydraulics.length - 1;
  const newRow = {
    name: '',
    details: [],
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
    isDirty: true,
    notes: ''
  };
  newHydraulics.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: newHydraulics,
      unModifiedData: newHydraulics,
      units: existUnit,
      paginatedElements: newHydraulics.length,
    },
  });
};

export const copyHydraulicsRow = (row) => (dispatch) => {
  const { hydraulics } = store.getState();
  const newHydraulics = [...hydraulics.data];
  const existUnit = { ...hydraulics.units };
  const nextId = newHydraulics.length - 1;
  const newRow = {
    name: '',
    run_details: row?.run_details,
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
    notes: ''
  };
  newHydraulics.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: newHydraulics,
      unModifiedData: hydraulics.unModifiedData,
      paginatedElements: newHydraulics.length,
      units: existUnit,
    },
  });
};

export const addNewHydraulicsDetailsRow = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const { hydraulics } = store.getState();
  const newHydraulicsDetails = [...hydraulics.details.data];
  const existUnit = { ...hydraulics.units };
  const nextId = newHydraulicsDetails.length - 1;
  const newRow = {
    hole_depth: '',
    bit_depth_offset: 0.0,
    rop: '',
    flow_in: '',
    drillStringsId: '',
    drillFluidsId: '',
    cuttings_data: {
      diameter: 0.025,
      thickness: 0.025,
      density: 21.75,
      porosity: 0.22
    },
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
    isDirty: true,
  };
  newHydraulicsDetails.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
    payload: {
      data: newHydraulicsDetails,
      unModifiedData: newHydraulicsDetails,
      units: existUnit,
      paginatedElements: newHydraulicsDetails.length,
    },
  });
};

export const listHydraulics = (query) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_LOADING,
  });
  const body = {
    query
  };
  return fetch
    .post('hydraulicsRuns/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { data: hydraulicsData, units } = data;

      const listData = hydraulicsData.map((item) => {
        const modifiedRunDetails = modifyMetricData([item.run_details], units?.run_details, currentUnit)[0];

        const { summary, depth_points, pump_ramps } = item.run_response || {};
        const { cuttings_data, trip_data } = item.run_details || {};

        const isRunDetailsEmpty = Object.keys(item.run_details || {}).length === 0;

        const modifiedSummary = modifyMetricData([summary], units?.run_response?.summary, currentUnit)[0];
        const modifiedDepthPoints = modifyMetricData(depth_points, units?.run_response?.depth_points, currentUnit);
        const modifiedCuttingData = modifyMetricData([cuttings_data], units?.cuttings_data, currentUnit)[0];
        const modifiedTripData = modifyMetricData([trip_data], units?.trip_data, currentUnit)[0];
        const modifiedPumpRamps = modifyMetricData(pump_ramps, units?.run_response?.pump_ramps, currentUnit);

        return {
          ...item,
          run_details: isRunDetailsEmpty
            ? {}
            : {
                ...modifiedRunDetails,
                cuttings_data: modifiedCuttingData,
                trip_data: modifiedTripData,
              },
          run_response: {
            ...item.run_response,
            pump_ramps: modifiedPumpRamps,
            summary: modifiedSummary,
            depth_points: modifiedDepthPoints
          },
        };
      });
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
        payload: {
          data: listData,
          unModifiedData: data.data,
          paginatedElements: listData.length,
          units: data.units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const createHydraulics = (body, jobId, wellsInfoId, setId, wellsPage) => (dispatch) => {
  const { name, run_details, notes } = body;
  delete body._id;
  delete body.isDirty;
  const input = {
    input: {
      name,
      run_details: run_details || {},
      wellsInfoId,
      jobId: (jobId || '-1'),
      notes
    }
  };
  const query = wellsPage
    ? {
        wellsInfoId
      }
    : {
        jobId
      };
  return fetch
    .post('hydraulicsRuns/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      if (setId) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_SET_ID,
          payload: {
            data: data.data._id,
          },
        });
      }
      dispatch(
        enqueueSnackbar(
          'Hydraulics Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};
export const updateHydraulics = (body, wellsPage) => (dispatch) => {
  delete body.isDirty;
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      notes: body?.notes,
      jobId: body?.jobId,
      wellsInfoId: body?.wellsInfoId,
      name: body?.name
    }
  };
  const query = wellsPage
    ? {
        wellsInfoId: body?.wellsInfoId
      }
    : {
        jobId: body?.jobId
      };
  return fetch
    .put('hydraulicsRuns', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};
export const deleteHydraulics = (id, query) => (dispatch) => {
  const body = {
    query: {
      _id: id
    }
  };
  return fetch
    .deleteAction('hydraulicsRuns', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};

export const listHydraulicsDetails = (runsId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const body = {
    query: {
      runsId
    }
  };
  return fetch
    .post('hydraulicsRuns/details/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { data: hydraulicsDetailData, units } = data;
      const modifiedHydraulicsDetailData = modifyMetricData(hydraulicsDetailData, units.run_details, currentUnit);
      if (modifiedHydraulicsDetailData[0]?.cuttings_data) {
        const cuttingData = modifyMetricData(
          [modifiedHydraulicsDetailData[0].cuttings_data],
          units.cuttings_data,
          currentUnit
        );
        modifiedHydraulicsDetailData[0].cuttings_data = cuttingData[0];
      }
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
        payload: {
          unModifiedData: data.data,
          data: modifiedHydraulicsDetailData,
          paginatedElements: modifiedHydraulicsDetailData.length,
          units: data.units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const updateHydraulicProperty = (data) => (dispatch) => {
  const { hydraulics } = store.getState();
  const currentHydraulics = [...hydraulics.data];
  const currentUnModifiedHydraulics = [...hydraulics.unModifiedData];
  const index = currentHydraulics.findIndex(hydraulic => hydraulic._id === data._id);

  if (Array.isArray(data.run_details) && data.run_details.length === 1) {
    data.run_details = data.run_details[0];
  }
  currentHydraulics[index] = data;
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: currentHydraulics,
      unModifiedData: currentUnModifiedHydraulics,
      paginatedElements: currentHydraulics.length,
      units: hydraulics.units

    },
  });
};

export const createHydraulicsDetails = (runsId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  delete body._id;
  const input = {
    query: {
      runsId
    },
    input: body
  };
  return fetch
    .post('hydraulicsRuns/details/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    });
};
export const updateHydraulicsDetails = (runsId, body) => (dispatch) => {
  delete body?._id;
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const input = {
    query: {
      runsId,
    },
    input: body
  };
  return fetch
    .put('hydraulicsRuns/details', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    });
};
export const deleteHydraulicsDetails = (runsId) => (dispatch) => {
  const input = {
    query: {
      runsId
    },
  };
  return fetch
    .deleteAction('hydraulicsRuns/details', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
    });
};
export const runHydraulics = (body, onClose) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_LOADING,
  });
  if (onClose) {
    dispatch({
      type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
    });
  }
  const { runId, query } = body;
  const input = {
    input: {
      url: `/runs/${runId}`,
      method: 'POST',
    }
  };
  return fetch
    .post('premiumHydraulics/proxy', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Simulation Completed Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SET_FALSE,
        });
        onClose();
      }
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SET_FALSE,
        });
      }
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};

export const fetchHydraulicsDrillstringDrillFluid = (wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_LOADING,
  });
  const body = {
    query: {
      wellsInfoId
    }
  };
  return fetch
    .post('hydraulicsRuns/selections/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { result: drillData, units } = data;
      const modifiedDrillFluidData = modifyMetricData(drillData.drillFluids, units, currentUnit);
      const modifiedDrillStringData = modifyMetricData(drillData.drillStrings, units, currentUnit);

      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_SUCCESS,
        payload: {
          data: {
            drillFluids: modifiedDrillFluidData,
            drillStrings: modifiedDrillStringData
          },
          units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const listMudPumps = (wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId
    }
  };
  return fetch
    .post('hydraulicsMudPumps/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { data: mudPumpData, units } = data;
      const modifiedMudPumpData = modifyMetricData(mudPumpData, units, currentUnit);
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_SUCCESS,
        payload: {
          data: modifiedMudPumpData,
          paginatedElements: modifiedMudPumpData.length,
          units: data.units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const createMudPumps = (body, wellsInfoId, jobId) => (dispatch) => {
  delete body.isNewRow;
  delete body._id;
  const input = {
    input: {
      ...body,
      wellsInfoId,
      jobId
    }
  };
  return fetch
    .post('hydraulicsMudPumps/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    });
};
export const updateMudPumps = (body, payload) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING
  });
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      ...payload,
      wellsInfoId: body.wellsInfoId,
      jobId: body.jobId,
      spm: Number(body.spm)
    }
  };
  return fetch
    .put('hydraulicsMudPumps', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(body?.wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(body?.wellsInfoId));
    });
};
export const deleteMudPumps = (_id, wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING
  });
  const body = {
    query: {
      _id,
    }
  };
  return fetch
    .deleteAction('hydraulicsMudPumps', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    });
};
export const listMudDisplacement = (mudDisplacement) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: mudDisplacement.wellsInfoId,
      runId: mudDisplacement.runId,
    }
  };
  return fetch
    .post('hydraulicsDisplacements/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { data: mudDisplacementData, units } = data;
      if (mudDisplacementData[0]?.results) {
        const resultsData = modifyMetricData(
          mudDisplacementData[0].results,
          units,
          currentUnit
        );
        mudDisplacementData[0].results = resultsData;
      }
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_SUCCESS,
        payload: {
          data: mudDisplacementData,
          paginatedElements: mudDisplacementData.length,
          units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const createMudDisplacement = (body) => (dispatch) => {
  delete body.isNewRow;
  delete body._id;
  const input = {
    input: {
      wellsInfoId: body.wellsInfoId,
      runId: body.runId,
      fromFluidId: body.fromFluidId,
      toFluidId: body.toFluidId,
      pumps_output: body.pumps_output
    }
  };
  return fetch
    .post('hydraulicsDisplacements/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Displacement Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      const mudDisplacement = {
        runId: body.runId,
        wellsInfoId: body.wellsInfoId
      };
      dispatch(listMudDisplacement(mudDisplacement));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      const mudDisplacement = {
        runId: body.runId,
        wellsInfoId: body.wellsInfoId
      };
      dispatch(listMudDisplacement(mudDisplacement));
    });
};
export const updateMudDisplacement = (row, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_LOADING
  });
  const input = {
    query: {
      _id: row?._id
    },
    input: {
      wellsInfoId: body.wellsInfoId,
      runId: body.runId,
      fromFluidId: body.fromFluidId,
      toFluidId: body.toFluidId,
      pumps_output: body.pumps_output
    }
  };
  return fetch
    .put('hydraulicsDisplacements', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Displacement Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      const mudDisplacement = {
        runId: body.runId,
        wellsInfoId: body.wellsInfoId
      };
      dispatch(listMudDisplacement(mudDisplacement));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      const mudDisplacement = {
        runId: body.runId,
        wellsInfoId: body.wellsInfoId
      };
      dispatch(listMudDisplacement(mudDisplacement));
    });
};
export const deleteMudDisplacement = (mudDisplacement) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_LOADING
  });
  const body = {
    query: {
      _id: mudDisplacement._id,
    }
  };
  return fetch
    .deleteAction('hydraulicsDisplacements', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Displacement deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudDisplacement(mudDisplacement));
      dispatch(listHydraulics(mudDisplacement.query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudDisplacement(mudDisplacement));
      dispatch(listHydraulics(mudDisplacement.query));
    });
};
export const runDisplacement = (body, onClose) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_LOADING,
  });
  if (onClose) {
    dispatch({
      type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_RUN_LOADING,
    });
  }
  const { runId, query } = body;
  const input = {
    input: {
      url: `/runs/displacement/${runId}`,
      method: 'POST',
    }
  };
  return fetch
    .post('premiumHydraulics/proxy', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Displacement Simulation Completed Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_RUN_SUCCESS,
        });
        onClose();
      }
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_RUN_SUCCESS,
        });
      }
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};

export const downloadRunInputs = (payload) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_LOADING,
  });
  const body = {
    query: {
      runsId: payload.runsId,
      wellsInfoId: payload.wellsInfoId
    }
  };
  return fetch
    .downloadPost('hydraulicsRuns/inputs/download', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const fileName = `${payload.fileName}.json`;
      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_IDLE,
      });
      dispatch(
        enqueueSnackbar(
          ' Run model input downloaded successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_IDLE,
      });
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
    });
};
