/* eslint-disable react/prop-types */
import React from 'react';
import styles from './styles';
import { Fade, Grid, IconButton, Modal } from '@mui/material';
import ButtonComponent from '../../../../Button';
import { CancelPresentationRounded } from '@mui/icons-material';

const SendBinRemindersModal = (props) => {
  const {
    open,
    onClose,
    children,
    showCta2,
    cta2Label,
    cta2Disabled,
    cta2Action,
    mobile
  } = props;
  const classes = styles();

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={mobile ? classes.paperMobile : classes.paper}>
          <Grid container alignItems="center" justifyContent="end">
            <Grid item>
            <IconButton onClick={() => handleClose()}>
                <CancelPresentationRounded fontSize='large'/>
              </IconButton>
            </Grid>
          </Grid>

          <p id="transition-modal-description"></p>
          {children}
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            {showCta2 && (
              <ButtonComponent label={cta2Label} onClick={cta2Action} className={classes.button} disabled={cta2Disabled} />
            )}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default SendBinRemindersModal;
