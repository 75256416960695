import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import styles from './styles';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from '../../../../utils/isMobile';
const SearchBox = () => {
  const mobile = isMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchTerm } = useSelector((state) => state.searchBox);
  const classes = styles();
  const [filter, setFilter] = useState(null);
  const [searchChange, setSearchChange] = useState(null);
  const { preferences } = useSelector((state) => state.currentUser);

  const getActionType = () => {
    switch (location.pathname) {
      case '/Jobs':
        return {
          filter: REDUX_ACTIONS.JOBS_FILTER,
          searchChange: REDUX_ACTIONS.JOBS_SEARCH_CHANGE
        };
      case '/Shop':
        return {
          filter: REDUX_ACTIONS.PARTS_LIST_FILTER,
          searchChange: REDUX_ACTIONS.PARTS_SEARCH_CHANGE
        };
      case '/FieldBoxes':
        return {
          filter: REDUX_ACTIONS.EPICOR_BINS_FILTER,
          searchChange: REDUX_ACTIONS.EPICOR_BINS_SEARCH_CHANGE
        };
      case '/Wells':
        return {
          filter: REDUX_ACTIONS.WELLS_FILTER,
          searchChange: REDUX_ACTIONS.WELLS_SEARCH_CHANGE
        };
      case '/Enverus/Rigs':
        return {
          filter: REDUX_ACTIONS.RIGS_FILTER,
          searchChange: REDUX_ACTIONS.RIGS_SEARCH_CHANGE
        };
      case '/AssetTypes':
        return {
          filter: REDUX_ACTIONS.ASSETTYPE_FILTER,
          searchChange: REDUX_ACTIONS.ASSETTYPE_SEARCH_CHANGE
        };
      case '/Enverus/Wells':
        return {
          filter: REDUX_ACTIONS.ENVERUS_WELLS_FILTER,
          searchChange: REDUX_ACTIONS.ENVERUS_WELLS_SEARCH_CHANGE
        };
      case '/Marketshare':
        return {
          filter: REDUX_ACTIONS.DBW_VS_TARGET_FILTER,
          searchChange: REDUX_ACTIONS.DBW_VS_TARGET_SEARCH_CHANGE
        };
      // Add more cases as needed
      default:
        return {
          filter: null,
          searchChange: null,
        };
    }
  };
  useEffect(() => {
    const { filter, searchChange } = getActionType();
    setFilter(filter);
    setSearchChange(searchChange);
    // clean search and reload all items on page change
    dispatch({
      type: searchChange,
      payload: { value: '' }
    });
    dispatch({
      type: filter,
    });
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: '' },
    });
  }, [location.pathname]);

  const handleSearch = (value) => {
    if (mobile && (!searchTerm || searchTerm === '') && location.search) {
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_RESET,
      });
      navigate(location.pathname, { replace: true });
      return;
    } else if (mobile && (!searchTerm || searchTerm === '') && !location.search) {
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_RESET,
      });
    }
    dispatch({
      type: searchChange,
      payload: { value }
    });
    dispatch({
      type: filter,
    });
  };

  const handleOnChange = (e) => {
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: e.target.value },
    });
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  return (
    <Box sx={{ marginLeft: mobile ? 0 : 'auto' }}>
      <FormControl fullWidth style={{ marginRight: preferences.marketPlaceInventoryView === 2 ? '30px' : '5px' }}>
        <InputLabel
          htmlFor="outlined-adornment-search"
          inputprops={{ classes: { root: classes.searchBar } }}
        >
          Search
        </InputLabel>
        <FilledInput
          className={classes.searchBar}
          id="outlined-adornment-search"
          type="text"
          label="Search"
          value={searchTerm}
          onChange={handleOnChange}
          onKeyDown={handleEnter}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => handleSearch(searchTerm)}
              >
                <Search/>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};

export default SearchBox;
