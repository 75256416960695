import fetchAPI from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import { listWells } from './wells';
import { modifyMetricData } from '../../utils/getMetrics';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const listStrippingData = (wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .post('wells/entity/stripping/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      const { currentUser } = store.getState();
      const currentUnit = currentUser?.preferences?.unitSystem;
      const { data: strippingData, units } = data;
      const modifiedStrippingData = modifyMetricData(strippingData, units, currentUnit);
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_SUCCESS,
        payload: {
          data: modifiedStrippingData,
          paginatedElements: modifiedStrippingData.length,
          units: data.units
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewStrippingRow = () => (dispatch) => {
  const { hydraulicsStripping } = store.getState();
  const newStripping = [...hydraulicsStripping.data];
  const existUnit = { ...hydraulicsStripping.units };
  const nextId = newStripping.length - 1;
  const newRow = {
    name: '',
    speed: 0.0,
    type: 'In',
    config: 'Drillstring',
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
    isDirty: true
  };
  newStripping.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_SUCCESS,
    payload: {
      data: newStripping,
      units: existUnit,
      paginatedElements: newStripping.length,
    },
  });
};

export const createStrippingData = (body, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_LOADING,
  });
  const input = {
    query: {
      wellsInfoId: wellId
    },
    input: {
      ...body,
    }
  };
  return fetchAPI
    .post('wells/entity/stripping/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Stripping Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
    });
};

export const updateStrippingData = (body, wellId, rowId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_LOADING,
  });
  const input = {
    query: {
      _id: rowId,
      wellsInfoId: wellId
    },
    input: {
      ...body
    }
  };
  return fetchAPI
    .put('wells/entity/stripping', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Stripping Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
    });
};

export const deleteStrippingRow = (deleteStrippingId, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_STRIPPING_LIST_LOADING,
  });
  const body = {
    query: {
      _id: deleteStrippingId,
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .deleteAction('wells/entity/stripping', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Stripping Details Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listStrippingData(wellId));
    });
};
