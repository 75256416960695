import { REDUX_ACTIONS } from '../../shared/config/constants';
const initialState = {
  loading: false,
  deleting: false,
  data: [],
  unModifiedData: [],
  units: {},
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  drillDetails: {
    loading: false,
    hydraulicsDrillDetails: {},
    units: {}
  },
  details: {
    unModifiedData: [],
    data: [],
    units: {},
    paginatedElements: 0,
  },
  mudPumps: {
    data: [],
    units: {},
    paginatedElements: 0,
  },
  mudDisplacement: {
    data: [],
    units: {},
    paginatedElements: 0
  },
  downloadModel: 'idle'
};
const hydraulicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.HYDRAULICS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        unModifiedData: [...action.payload.unModifiedData],
        units: { ...action.payload.units },
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.HYDRAULICS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.HYDRAULICS_SET_ID:
      return {
        ...state,
        hydraulicsId: action.payload.data
      };
    case REDUX_ACTIONS.HYDRAULICS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          unModifiedData: [...action.payload.unModifiedData],
          data: [...action.payload.data],
          units: { ...action.payload.units },
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_ERROR:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          errorMessage: action.payload.message,
          data: []
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SET_FALSE:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_LOADING:
      return {
        ...state,
        drillDetails: {
          ...state.drillDetails,
          errorMessage: '',
          loading: true,
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_SUCCESS:
      return {
        ...state,
        drillDetails: {
          ...state.drillDetails,
          loading: false,
          units: { ...action.payload.units },
          hydraulicsDrillDetails: { ...action.payload.data },
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_ERROR:
      return {
        ...state,
        drillDetails: {
          ...state.drillDetails,
          loading: false,
          errorMessage: action.payload.message,
          hydraulicsDrillDetails: { ...action.payload.data }
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_RESET:
      return {
        ...state,
        drillDetails: {
          ...state.drillDetails,
          loading: false,
          errorMessage: action.payload.message,
          hydraulicsDrillDetails: { }
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_RESET:
      return {
        ...initialState
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING:
      return {
        ...state,
        mudPumps: {
          ...state.mudPumps,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_SUCCESS:
      return {
        ...state,
        mudPumps: {
          ...state.mudPumps,
          loading: false,
          data: [...action.payload.data],
          units: { ...action.payload.units },
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_ERROR:
      return {
        ...state,
        mudPumps: {
          ...state.mudPumps,
          loading: false,
          errorMessage: action.payload.message,
          data: []
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_LOADING:
      return {
        ...state,
        mudDisplacement: {
          ...state.mudDisplacement,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_SUCCESS:
      return {
        ...state,
        mudDisplacement: {
          ...state.mudDisplacement,
          loading: false,
          data: [...action.payload.data],
          units: { ...action.payload.units },
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_LIST_ERROR:
      return {
        ...state,
        mudDisplacement: {
          ...state.mudDisplacement,
          loading: false,
          errorMessage: action.payload.message,
          data: []
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_RUN_LOADING:
      return {
        ...state,
        mudDisplacement: {
          ...state.mudDisplacement,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_MUD_DISPLACEMENT_RUN_SUCCESS:
      return {
        ...state,
        mudDisplacement: {
          ...state.mudDisplacement,
          loading: false,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_LOADING:
      return {
        ...state,
        downloadModel: 'loading'
      };
    case REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_IDLE:
      return {
        ...state,
        downloadModel: 'idle'
      };
    case REDUX_ACTIONS.HYDRAULICS_DOWNLOAD_MODEL_ERROR:
      return {
        ...state,
        downloadModel: 'error'
      };
    default:
      return state;
  }
};
export default hydraulicsReducer;
