import { modifyMetricData } from '../../utils/getMetrics';
import { UNIT_SYSTEM } from '../../shared/config/hydraulics_constants';
const columns = (renderCell, RenderJsonField, renderActions, renderValueCell, renderValueTextCell, drillFluidUnit, currentUnit) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderEditCell: renderValueTextCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'MD',
    headerName: `MD (${drillFluidUnit?.MD?.label})`,
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(drillFluidUnit?.MD?.decimals);
    },
  },
  {
    field: 'details',
    headerName: 'Details',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      const filteredData = Array.isArray(data?.value) && data?.value?.length
        ? data.value.map((item) => ({
          mw: item.mw,
          fann_600: item.fann_600,
          fann_300: item.fann_300,
          fann_200: item.fann_200,
          fann_100: item.fann_100,
          fann_6: item.fann_6,
          fann_3: item.fann_3,
          gel_10min: item.gel_10min,
          gel_10sec: item.gel_10sec,
          tank_vol: item.tank_vol,
          pv: parseFloat((item.fann_600 - item.fann_300).toFixed(drillFluidUnit?.details?.pv?.decimals)),
          yp: parseFloat((item.fann_300 - (item.fann_600 - item.fann_300)).toFixed(drillFluidUnit?.details?.yp?.decimals)),
        }))
        : [];

      const convertedData = modifyMetricData(filteredData, drillFluidUnit?.details, currentUnit);

      return <RenderJsonField value={convertedData} />;
    }

  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 160,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
