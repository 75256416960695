import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (pumpRampUnits, currentUnit) => [
  {
    field: 'steps',
    headerName: 'Steps',
    headerAlign: 'center',
    align: 'center',
    width: 80,
  },
  {
    field: 'flow_in',
    headerName: `Flow In (${pumpRampUnits?.flow_in?.label})`,
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(pumpRampUnits?.flow_in?.decimals);
    },
  },
  {
    field: 'sbp',
    headerName: `SBP (${pumpRampUnits?.sbp?.label})`,
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(pumpRampUnits?.sbp?.decimals);
    },
  },
];
export default columns;
