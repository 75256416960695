import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

export const listHydraulicsLogs = (payload) => (dispatch) => {
  const { hydraulicsLogs } = store.getState();

  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LOGS_LIST_LOADING,
  });

  const body = {
    query: {
      startDate: payload?.startDate || hydraulicsLogs?.startDate,
      endDate: payload?.endDate || hydraulicsLogs?.endDate,
      searchValue: payload?.searchValue || hydraulicsLogs?.searchValue,
      limit: 100,
      page: payload?.page || 1,
      filters: hydraulicsLogs?.filters?.items || [],
      linkOperator: hydraulicsLogs?.filters?.linkOperator || '',
      sort: hydraulicsLogs?.sort || null
    }
  };

  return fetch
    .post('audit/hydraulics', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LOGS_LIST_SUCCESS,
        payload: {
          hydraulicsLogs: data.data,
          paginatedElements: data.data.count,
          totalCount: data.count
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LOGS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
