import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { formatCurrency } from '../../utils/numbers';
import { enqueueSnackbar } from './snackbar';

export const getSpendChartData = (getDataFor, id, payload) => (dispatch) => {
  if (payload.type) {
    dispatch({
      type: REDUX_ACTIONS.SPEND_CHART_SET_FILTER_TYPE,
      payload: payload.type
    });
  }
  dispatch({
    type: REDUX_ACTIONS.SPEND_CHART_DATA_LOADING,
  });

  const body = {
    query: {
      [getDataFor === 'user' ? 'userId' : 'companyId']: id,
      ...payload,
    },
  };

  return fetch
    .post(`orders/spendSummary/${getDataFor}`, body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.SPEND_CHART_DATA,
        payload: data?.data?.map((item) => {
          const date = new Date(item.groupKey);
          date.setHours(0, 0, 0, 0);
          return {
            x: date,
            y: item.totalPrice,
            ...(payload.type === 'year'
              ? {
                  indexLabel: `${(formatCurrency(item.totalPrice)).toString()}`
                }
              : {})
          };
        })
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.SPEND_CHART_DATA_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const getVendorsList = (getDataFor, id) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.SPEND_VENDORS_LOADING,
  });

  const body = {
    query: {
      [getDataFor === 'user' ? 'userId' : 'companyId']: id,
    },
  };

  return fetch
    .post(`selectBox/orders/${getDataFor}/vendors`, body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.SPEND_VENDORS_DATA,
        payload: data?.data
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.SPEND_VENDORS_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
