import fetch from '../../../api/fetch';
import { enqueueSnackbar } from '../snackbar';
import { REDUX_ACTIONS } from '../../../shared/config/constants';

export const epicorSendBinReminders = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_LOADING,
    payload: {
      message: 'Please wait...',
    },
  });

  const body = {
    url: '/bins/reminderEmails',
    type: 'POST',
  };
  return fetch
    .post('epicorProxy/epicorProxyPost', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((res) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_SUCCESS,
        payload: {
          message: 'Reminders Sent',
        },
      });
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Reminder Failed',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_ERROR,
        payload: {
          message: 'Reminder Failed',
        },
      });
    });
};
