import React, { useEffect, useRef, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { CancelPresentationRounded, DirectionsRunRounded, FastForward, FastRewind } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CanvasJSReact from '../canvasjs/canvasjs.react';
import { getSpendChartData, getVendorsList } from '../../../store/actions/spend';
import ModalDialog from '../ModalDialog';
import styles from './styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { checkDateDifference } from '../../../utils/getDateRange';
import { USER_TYPE } from '../../../utils/constants';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { calculateDaysDifference, shiftDate } from '../../../utils/dateInfo';
import { formatCurrency } from '../../../utils/numbers';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const chartDefaults = {
  exportEnabled: true,
  animationEnabled: true,
  theme: 'light2',
};

const SpendChartDialog = ({ open, handleClose, rowData, type }) => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const classes = styles();
  const { data, loading, filterType, total } = useSelector((state) => state.spend.spendChartData);
  const { data: vendorsData, loading: vendorsLoading, defaultVendor } = useSelector((state) => state.spend.vendors);
  const user = useSelector((state) => state.currentUser);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(new Date());

  useEffect(() => {
    if (user.type !== USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
      dispatch(getVendorsList(type, rowData?._id));
    }
    getChartData();
  }, []);

  const getChartData = (e) => {
    dispatch({
      type: REDUX_ACTIONS.SPEND_VENDORS_SET_SELECTED,
      payload: e?.target?.value || 'ALL'
    });
    const payload = {
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime,
      type: checkDateDifference(selectedStartDateTime, selectedEndDateTime)
    };

    if (e?.target?.value && e?.target?.value !== 'ALL') {
      payload.vendorId = e.target.value;
    }

    dispatch(getSpendChartData(type, rowData?._id, payload));
  };

  const calculateTimeDiff = (start, end) => {
    return Math.abs(new Date(end).getTime() - new Date(start).getTime());
  };

  const handleTimeShift = (type) => {
    let updatedStartDateTime = selectedStartDateTime ? new Date(selectedStartDateTime) : null;
    let updatedEndDateTime = selectedEndDateTime ? new Date(selectedEndDateTime) : null;

    if (updatedStartDateTime && updatedEndDateTime) {
      const timeDiff = calculateDaysDifference(updatedStartDateTime, updatedEndDateTime);

      if (type === 'increase') {
        updatedStartDateTime = shiftDate(updatedStartDateTime, timeDiff, 'forward');
        updatedEndDateTime = shiftDate(updatedEndDateTime, timeDiff, 'forward');

        if (updatedStartDateTime > new Date()) {
          return;
        }
      } else {
        updatedStartDateTime = shiftDate(updatedStartDateTime, timeDiff, 'reverse');
        updatedEndDateTime = shiftDate(updatedEndDateTime, timeDiff, 'reverse');
      }

      setSelectedStartDateTime(updatedStartDateTime);
      setSelectedEnDateTime(updatedEndDateTime);
    }
  };

  const isForwardDisabled = () => {
    if (selectedStartDateTime && selectedEndDateTime) {
      const timeDiff = calculateTimeDiff(selectedStartDateTime, selectedEndDateTime);
      const futureStartDate = new Date(selectedStartDateTime.getTime() + timeDiff);
      return futureStartDate > new Date();
    }
    return false;
  };

  const RangeFilter = () => {
    return (
      <Box className={classes.container}>
      <Box className={classes.vendorContainer} sx={{ display: user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN ? 'none' : 'flex' }}>
          <Typography variant='body2' className={classes.divisionText}>Vendor</Typography>
          <FormControl variant='outlined' sx={{ minWidth: '180px' }}>
            <Select
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={defaultVendor}
              onChange={getChartData}
              size='small'
              sx={{
                '& .MuiSelect-select': {
                  padding: '14px',
                },
              }}
              disabled={vendorsLoading}
            >
              <MenuItem
                  key={'ALL'}
                  value={'ALL'}
                >
                  ALL
                </MenuItem>
              {vendorsData?.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                <MenuItem
                  key={`item_${item._id}`}
                  value={item._id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      <Box className={classes.dateSwiftContainer}>
           <IconButton
           onClick={() => handleTimeShift('decrease')}
            disabled={loading || (selectedStartDateTime > selectedEndDateTime)}>
             <FastRewind />
          </IconButton>
          <IconButton
          onClick={() => handleTimeShift('increase')}
          disabled={loading || isForwardDisabled() || (selectedStartDateTime > selectedEndDateTime)}
          >
            <FastForward />
          </IconButton>
      </Box>
          <Box className={classes.dateContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format='MM/dd/yyyy'
              disableFuture
              label="Start Date"
              value={selectedStartDateTime}
              onChange={(date) => setSelectedStartDateTime(date)}
              className={classes.dateTimePicker}
              textField={(params) => <TextField {...params} />}
              disabled={loading}
            />
            <DatePicker
              format='MM/dd/yyyy'
              label="End Date"
              value={selectedEndDateTime}
              onChange={(date) => setSelectedEnDateTime(date)}
              className={classes.dateTimePicker}
              minDate={selectedStartDateTime}
              disableFuture
              textField={(params) => <TextField {...params} />}
              disabled={loading}
            />
            </LocalizationProvider>
          </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            disabled={loading || isForwardDisabled() || (selectedStartDateTime > selectedEndDateTime) || (selectedStartDateTime === null && selectedEndDateTime === null) || selectedEndDateTime > new Date()}
            size="large"
            onClick={getChartData}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const getModalTitle = () => {
    return (
      <>
        <Grid className={classes.flexAlign} justifyContent="space-between">
          <Grid item className={classes.flexAlign}>
            <Grid>
            <Typography
                  variant='body1'
                  className={classes.text}>
                      {rowData.name || rowData.firstName}
                  </Typography>
            </Grid>
            <Grid className={classes.flexAlign} style={{ display: !data.length && 'none' }}>
                 <Typography
                  variant='body1'
                  className={classes.text}>
                    Total: {formatCurrency(total)}
                  </Typography>
                 </Grid>
          </Grid>
          <Grid item className={classes.flexAlign}>
            <Grid sx={{ mr: 1 }}>
            <RangeFilter />
            </Grid>
            <IconButton
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <React.Fragment>
      <ModalDialog
        title={getModalTitle()}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        isOpen={open}
        fullWidth
        maxWidth='xl'
      >
        <DialogContent>
        {loading
          ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
            )
          : (
          <>
            {data?.length
              ? (
              <>
                <Box sx={{ flex: 1, marginTop: '10px' }}>
                  <CanvasJSChart
                  ref={chartRef}
                  options={{
                    ...chartDefaults,
                    dataPointWidth: 50,
                    height: 400,
                    axisX: {
                      interval: data.length > 1 && 1,
                      ...(filterType === 'year'
                        ? {
                            intervalType: 'month',
                            valueFormatString: 'MMMM'
                          }
                        : {
                            labelAngle: -50,
                            intervalType: 'day',
                            valueFormatString: 'D MMM Y'
                          })
                    },
                    axisY: {
                      title: 'Spend',
                      includeZero: true,
                    },
                    data: [{
                      type: filterType === 'year' ? 'column' : 'line',
                      dataPoints: data,
                      indexLabelPlacement: 'outside',
                      indexLabelFontColor: '#000',
                      indexLabelFontWeight: 'bold',
                      indexLabelFontSize: 12,
                      // eslint-disable-next-line no-template-curly-in-string
                      toolTipContent: '{x}: ${y}'
                    },
                    ]
                  }} />
                </Box>
              </>
                )
              : (
              <Typography variant='h6' sx={{ display: 'grid', placeItems: 'center', height: '400px' }}>
                No data to show for this {filterType}
              </Typography>
                )}
          </>
            )}
        </DialogContent>
      </ModalDialog>
    </React.Fragment>
  );
};

SpendChartDialog.propTypes = {
  open: PropTypes.bool,
  rowData: PropTypes.object,
  handleClose: PropTypes.func,
  type: PropTypes.string
};

export default SpendChartDialog;
