import fetch from '../../../api/fetch';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import { convertChartData, filterAndTransformChartData, generateUpdatedArrayWithMappedDivisions, getUniqueDivisions } from '../../../utils/fieldboxKpiDataGenerator';
import store from '../../store';
import { enqueueSnackbar } from '../snackbar';

export const listEpicorBins = (params) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_BINS_LIST_LOADING,
  });

  let body = {};
  if (params) {
    const filteredParams = Object.entries({
      top: params.topValue,
      company: 'POT',
      select: params.selectValue,
      expand: params.expandValue,
      filter: params.filterValue,
      orderBy: params.orderByValue,
      skip: params.skipValue,
    }).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        value = value.trim();
      }
      if (value !== '' && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    body = {
      type: 'GET',
      url: '/bins',
      params: filteredParams,
    };
  } else {
    body = {
      type: 'GET',
      url: '/bins',
      params: {
        top: 2500,
        company: 'POT',
        exclusion: 'BinType_c ne \'Marketplac\''
      }
    };
  }

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_LIST_SUCCESS,
        payload: {
          epicorBins: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const listEpicorBinsUnfiltered = (params) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_BINS_LIST_LOADING,
  });

  let body = {};
  if (params) {
    const filteredParams = Object.entries({
      top: params.topValue,
      company: 'POT',
      select: params.selectValue,
      expand: params.expandValue,
      filter: params.filterValue,
      orderBy: params.orderByValue,
      skip: params.skipValue,
    }).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        value = value.trim();
      }
      if (value !== '' && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    body = {
      type: 'GET',
      url: '/bins/unfiltered',
      params: filteredParams,
    };
  } else {
    body = {
      type: 'GET',
      url: '/bins/unfiltered',
      params: {
        top: 2500,
        company: 'POT'
      }
    };
  }

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_LIST_SUCCESS,
        payload: {
          epicorBins: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const listBinsParts = (warehouseCode, binNum, binType) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_BINS_PARTS_LOADING,
  });

  const params = {
    company: 'POT',
    whseCode: warehouseCode,
    binNum,
    binType
  };
  const body = {
    type: 'GET',
    url: '/bins/parts',
    params,
  };
  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_PARTS_SUCCESS,
        payload: {
          parts: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_BINS_PARTS_ERROR_NOTIFIED,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const syncEpicoreBin = (binNumber) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_SYNC_BIN_LOADING,
    payload: {
      loadingBin: true,
      message: 'Please wait...',
    },
  });

  const body = {
    url: '/sync/syncBin',
    type: 'POST',
    binNum: binNumber
  };
  return fetch
    .post('epicorProxy/epicorProxyPost', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
    }).then(() => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SYNC_BIN_IDLE,
        payload: {
          loadingBin: false,
          message: 'Bin Synced Successfully'
        },
      });
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing bin',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SYNC_BIN_ERROR,
        payload: {
          loadingBin: false,
          message: 'Error in Sync'
        },
      });
    });
};

export const updateCoordinates = (value, params) => (dispatch) => {
  let body = {
    url: '/bins/location',
    type: 'PUT',
    binNum: value.BinNum,
  };

  if (params.field === 'Longitude') {
    body = {
      ...body,
      longitude: parseFloat(params.value),
      latitude: parseFloat(value.Latitude),
    };
  } else if (params.field === 'Latitude') {
    body = {
      ...body,
      latitude: parseFloat(params.value),
      longitude: parseFloat(value.Longitude),
    };
  }
  dispatch({
    type: REDUX_ACTIONS.EPICOR_UPDATE_COORDINATES,
    payload: {
      BinNum: body.binNum,
      Latitude: body.latitude,
      Longitude: body.longitude,
    }
  });
  return fetch
    .post('epicorProxy/epicorProxyPut', body)
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(res);
      }
    }).then(() => {
      dispatch(enqueueSnackbar(
        'Bin Updated Successfully',
        'success',
        new Date().getTime() + Math.random()
      ));
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error updating bin',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const getFieldboxesKPIs = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_LOADING,
  });

  const body = {
    type: 'GET',
    url: '/bins',
    params: {
      top: 2500,
      company: 'POT'
    }
  };

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      const updatedData = generateUpdatedArrayWithMappedDivisions(data.data);

      dispatch({
        type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_SUCCESS,
        payload: {
          data: updatedData,
          paginatedElements: updatedData.length,
          chartData: convertChartData(updatedData),
          divisions: getUniqueDivisions(updatedData)
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const updateFieldboxesKPIs = (data) => (dispatch) => {
  const updatedData = generateUpdatedArrayWithMappedDivisions(data);
  dispatch({
    type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_LOADING,
  });

  dispatch({
    type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_SUCCESS,
    payload: {
      data: updatedData,
      paginatedElements: updatedData.length,
      chartData: convertChartData(updatedData),
      divisions: getUniqueDivisions(updatedData)
    },
  });

  localStorage.removeItem('fieldboxes'); // cleanup to call api again on refresh
};

export const setSelectedDivision = (division) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_LOADING,
  });

  const initialData = store.getState().epicorBins.kpis.initialData;

  const { data, chartData } = filterAndTransformChartData(initialData, division);

  dispatch({
    type: REDUX_ACTIONS.FIELDBOXES_KPIS_SET_DIVISION,
    payload: {
      selectedDivision: division,
      data,
      chartData
    },
  });
};
