import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  dialogModal: {
    '& .MuiPaper-root': {
      maxWidth: '1460px',
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
}));

export default styles;
