import { UNIT_SYSTEM } from '../../shared/config/hydraulics_constants';

const columns = (renderValueCell, renderActions, windowUnit, currentUnit) => [
  {
    field: 'md',
    headerName: `Measured Depth (${windowUnit?.md?.label})`,
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(windowUnit?.md?.decimals);
    },
  },
  {
    field: 'pp',
    headerName: `Pore Pressure (${windowUnit?.pp?.label})`,
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(windowUnit?.pp?.decimals);
    },
  },
  {
    field: 'fp',
    headerName: `Fracture Pressure (${windowUnit?.fp?.label})`,
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(windowUnit?.fp?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 220,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
