import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (renderValueCell, renderDrillstringCell, renderDrillFluidCell, renderActions, renderCuttingsEditAction, renderTripsEditAction, hydraulicsUnit, currentUnit) => [
  {
    field: 'hole_depth',
    headerName: `Hole Depth (${hydraulicsUnit?.run_details?.hole_depth?.label})`,
    width: 180,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.hole_depth?.decimals));
    },
  },
  {
    field: 'bit_depth_offset',
    headerName: `Bit Off-Bottom Offset (${hydraulicsUnit?.run_details?.bit_depth_offset?.label})`,
    width: 240,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.bit_depth_offset?.decimals));
    },
  },
  {
    field: 'rop',
    headerName: `ROP (${hydraulicsUnit?.run_details?.rop?.label})`,
    width: 140,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.rop?.decimals));
    },
  },
  {
    field: 'rpm',
    headerName: `RPM (${hydraulicsUnit?.run_details?.rpm?.label})`,
    width: 130,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.rpm?.decimals));
    },
  },
  {
    field: 'flow_in',
    headerName: `Flow Rate (${hydraulicsUnit?.run_details?.flow_in?.label})`,
    width: 165,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.flow_in?.decimals));
    },
  },
  {
    field: 'sbp',
    headerName: `SBP (${hydraulicsUnit?.run_details?.sbp?.label})`,
    width: 130,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(hydraulicsUnit?.run_details?.sbp?.decimals));
    },
  },
  {
    field: 'drillStringsId',
    headerName: 'Drillstrings',
    width: 160,
    renderEditCell: renderDrillstringCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillFluidsId',
    headerName: 'Drilling Fluids',
    width: 160,
    renderEditCell: renderDrillFluidCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cuttings',
    headerName: 'Cuttings',
    width: 125,
    renderCell: renderCuttingsEditAction,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'trips',
    headerName: 'Stripping',
    width: 280,
    renderCell: renderTripsEditAction,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
