export const getDateInfo = (date, separator) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  const fullTime = date.toTimeString().split(':').join(':');
  const timeData = fullTime.split(' ');
  const shortTime = timeData[0];

  return {
    formated: month + separator + day + separator + year,
    time: shortTime,
    timeData,
    formattedDayTime: month + separator + day + separator + year + ' ' + shortTime
  };
};

export const getLastTwoWeeksDateRange = () => {
  const now = new Date(); // Current date and time

  const twoWeeksAgo = new Date(now);
  twoWeeksAgo.setDate(now.getDate() - 14);

  const selectedStartDateTime = twoWeeksAgo.toISOString();
  const selectedEndDateTime = now.toISOString();

  return {
    selectedStartDateTime,
    selectedEndDateTime
  };
};

export const getUTCOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();
  const totalMinutes = Math.abs(offsetMinutes);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const sign = offsetMinutes <= 0 ? '+' : '-';
  const formattedOffset = `${sign}${hours}:${minutes.toString().padStart(2, '0')}`;

  return formattedOffset;
};

export const calculateDaysDifference = (start, end) => {
  const normalizeDateToMidnightUTC = (date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(0, 0, 0, 0);
    return newDate;
  };

  const normalizedStart = normalizeDateToMidnightUTC(start);
  const normalizedEnd = normalizeDateToMidnightUTC(end);
  const timeDifference = Math.abs(normalizedEnd.getTime() - normalizedStart.getTime());

  const daysDifference = timeDifference / (1000 * 3600 * 24); // Milliseconds to days
  return daysDifference;
};

export const shiftDate = (date, days, type) => {
  const updatedDate = new Date(date);
  const shiftYear = days >= 365;
  const millisecondsInOneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  if (type === 'forward') {
    if (shiftYear) {
      updatedDate.setFullYear(updatedDate.getFullYear() + 1);
    } else {
      updatedDate.setTime(updatedDate.getTime() + (days * millisecondsInOneDay)); // Subtract days
    }
  } else {
    if (shiftYear) {
      updatedDate.setFullYear(updatedDate.getFullYear() - 1);
    } else {
      updatedDate.setTime(updatedDate.getTime() - (days * millisecondsInOneDay));
    }
  }
  return updatedDate;
};
