/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, Box, Dialog, Divider } from '@mui/material';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import { csvFileToArray } from '../../../utils/csvFileToArray';
import Datagrid from '../../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../../ui/Datagrid/table_config';
import { createDrillFluidDetailsProperty, deleteDrillFluidDetailsProperty, listDrillFluidDetails, updateDrillFluidDetails, updateDrillFluidDetailsProperty } from '../../../store/actions/wellsDrillFluid';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { WELLS_DRILL_FLUID_VALIDATION } from '../../../utils/constants';
import DeleteConfirmationModal from '../../deleteConfirmationModal';
import useUnsavedChanges from '../../../hooks/useUnsavedChanges';
import useUnitConversion from '../../../hooks/useUnitConversion';
import { UNIT_SYSTEM } from '../../../shared/config/hydraulics_constants';
import SelectEditInputTextField from '../../ui/SelectEditInputTextField';
function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
const DrillFluidDetails = ({
  handleClose,
  data,
  open,
  drillFluidData
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const drillFluid = useSelector((state) => state.wellsDrillFluid);
  const apiRef = useGridApiRef();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const fileReader = new FileReader();
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const { unit, convertUnitSystem } = useUnitConversion();
  const {
    unSavedExists,
    handleClose: unSavedDialogClose,
    UnsavedChangesModal
  } = useUnsavedChanges(properties, handleClose);

  useEffect(() => {
    if (!drillFluid.details.loading) {
      setProperties(drillFluid.details.data);
    }
    return () => {
      setProperties([]);
    };
  }, [drillFluid.details]);

  useEffect(() => {
    if (properties[0]?.mw === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'mw', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'mw');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      mw: '',
      fann_600: '',
      fann_300: '',
      fann_200: '',
      fann_100: '',
      fann_6: '',
      fann_3: '',
      gel_10min: '',
      gel_10sec: '',
      tank_vol: '',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true,
      isDirty: true
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };
  const handleImportedData = (csvData) => {
    const newAssetTypes = csvData.map((item) => {
      return {
        ...item,
        _id: generateRandom(),
      };
    });
    if (newAssetTypes) {
      setProperties(newAssetTypes);
    //   dispatch(updateAssetTypeDetails({ ...data, details: newAssetTypes }));
    }
  };

  const checkDrillFluidValues = (value) => {
    const row = value;
    const invalidFields = [];
    if (row?.mw === undefined || row?.mw === null || row?.mw === '') invalidFields.push('MW');
    if (row?.fann_600 === undefined || row?.fann_600 === null || row?.fann_600 === '') invalidFields.push('FANN600');
    if (row?.fann_300 === undefined || row?.fann_300 === null || row?.fann_300 === '') invalidFields.push('FANN300');
    if (row?.fann_200 === undefined || row?.fann_200 === null || row?.fann_200 === '') invalidFields.push('FANN200');
    if (row?.fann_100 === undefined || row?.fann_100 === null || row?.fann_100 === '') invalidFields.push('FANN100');
    if (row?.fann_6 === undefined || row?.fann_6 === null || row?.fann_6 === '') invalidFields.push('FANN6');
    if (row?.fann_3 === undefined || row?.fann_3 === null || row?.fann_3 === '') invalidFields.push('FANN3');
    if (row?.gel_10min === undefined || row?.gel_10min === null || row?.gel_10min === '') invalidFields.push('GEL 10 MIN');
    if (row?.gel_10sec === undefined || row?.gel_10sec === null || row?.gel_10sec === '') invalidFields.push('GEL 10 SEC');
    if (row?.tank_vol === undefined || row?.tank_vol === null || row?.tank_vol === '') invalidFields.push('TANK VOLUME');
    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const validateProperties = (row) => {
    const { mw, fann_600, fann_300, fann_200, fann_100, fann_6, fann_3, gel_10min, gel_10sec, tank_vol } = row;
    const minMw = drillFluid?.details?.units?.mw?.min;
    const maxMw = drillFluid?.details?.units?.mw?.max;
    if (mw <= minMw || mw >= maxMw) return `Mud Weight Should be between ${minMw} and ${maxMw} ${drillFluid?.details?.units?.mw?.label}`;
    if (fann_300 > fann_600) return WELLS_DRILL_FLUID_VALIDATION.FANN_300;
    if (fann_200 > fann_300) return WELLS_DRILL_FLUID_VALIDATION.FANN_200;
    if (fann_100 > fann_200) return WELLS_DRILL_FLUID_VALIDATION.FANN_100;
    if (fann_6 > fann_100) return WELLS_DRILL_FLUID_VALIDATION.FANN_6;
    if (fann_3 > fann_6 || fann_3 <= 0) return WELLS_DRILL_FLUID_VALIDATION.FANN_3;
    if (gel_10min < gel_10sec || gel_10min <= 0) return WELLS_DRILL_FLUID_VALIDATION.GEL_10MIN;
    if (gel_10sec <= 0) return WELLS_DRILL_FLUID_VALIDATION.GEL_10SEC;
    if (tank_vol < 0) return WELLS_DRILL_FLUID_VALIDATION.TANK_VOL;
    return '';
  };

  const saveProperty = (row) => {
    if (checkDrillFluidValues(row)) {
      for (const key in row) {
        if (typeof row[key] === 'string' && !isNaN(row[key])) {
          row[key] = Number(row[key]);
        }
      }
      const validationError = validateProperties(row);
      if (validationError === '') {
        const body = {
          mw: unit !== UNIT_SYSTEM.US ? convertUnitSystem(row.mw, drillFluid?.details?.units?.mw?.conversionFactor) : row.mw,
          fann_600: row.fann_600,
          fann_300: row.fann_300,
          fann_200: row.fann_200,
          fann_100: row.fann_100,
          fann_6: row.fann_6,
          fann_3: row.fann_3,
          gel_10min: unit !== UNIT_SYSTEM.US ? convertUnitSystem(row.gel_10min, drillFluid?.details?.units?.gel_10min?.conversionFactor) : row.gel_10min,
          gel_10sec: unit !== UNIT_SYSTEM.US ? convertUnitSystem(row.gel_10sec, drillFluid?.details?.units?.gel_10sec?.conversionFactor) : row.gel_10sec,
          tank_vol: unit !== UNIT_SYSTEM.US ? convertUnitSystem(row.tank_vol, drillFluid?.details?.units?.tank_vol?.conversionFactor) : row.tank_vol,
        };

        if (row?.isNewRow) {
          dispatch(createDrillFluidDetailsProperty(data?._id ? data?._id : drillFluid?.drillFluidId, body, drillFluidData?.row?.wellsInfoId));
        } else {
          dispatch(updateDrillFluidDetailsProperty(data?._id ? data?._id : drillFluid?.drillFluidId, row?._id, body, drillFluidData?.row?.wellsInfoId));
        }

        const newProperties = properties.map((item) => item._id === row._id ? row : item);
        const updatedData = {
          ...data,
          _id: drillFluid?.drillFluidId ? drillFluid?.drillFluidId : data?._id,
          details: newProperties
        };
        dispatch(updateDrillFluidDetails(updatedData));
      } else {
        if (validationError.length) setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        dispatch(enqueueSnackbar(validationError, 'error', new Date().getTime() + Math.random()));
      }
    }
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          const isDirty = value !== item[field];
          return {
            ...item,
            [field]: typeof value === 'string' ? value : Number(value),
            isDirty: item.isDirty || isDirty,
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const uploadCSVFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const importedAssetTypes = csvFileToArray(csvOutput, 'preserveType');
        handleImportedData(importedAssetTypes);
      };
      fileReader.readAsText(file);
    }
  };
  const handleDeleteDrillFluid = (row) => {
    const filteredProperties = properties.filter((item) => item._id !== row._id);
    const updatedDrillFluidData = {
      ...data,
      _id: drillFluid?.drillFluidId ? drillFluid?.drillFluidId : data?._id,
      details: filteredProperties
    };
    dispatch(updateDrillFluidDetails(updatedDrillFluidData));
    setProperties(filteredProperties);
    if (!row?.isNewRow) {
      dispatch(deleteDrillFluidDetailsProperty(data?._id ? data?._id : drillFluid?.drillFluidId, row?._id, drillFluidData?.row?.wellsInfoId));
    } else {
      dispatch(listDrillFluidDetails(data?._id ? data?._id : drillFluid?.drillFluidId));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const renderActions = ({
    row,
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
              variant="solid"
              fontSize='large'
              disabled={!row.isDirty || drillFluid.details.loading}
              onClick={() => {
                setTimeout(() => {
                  saveProperty(row);
                }, 100);
              }}
            >
              <SaveRounded />
            </IconButton>
        <IconButton
        variant="solid"
        disabled={drillFluid.details.loading}
        onClick={() => {
          setDeleteAction(true);
          setDeleteRow(row);
        }}
      >
        <DeleteRounded/>
      </IconButton>
      </Grid>
    );
  };

  const closeHandler = () => {
    if (unSavedExists) {
      unSavedDialogClose(false);
      return;
    }
    setProperties([]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'xl'}
      fullWidth={true}
      style={{
        marginRight: '20px'
      }}
    >
      <div className={classes.dialogTitle}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {drillFluidData?.row?.name?.trim()}, {data?.name || ''} {data?.name ? '@' : ''} {data.MD.toFixed(drillFluid?.units?.MD?.decimals)} {drillFluid?.units?.MD?.label}
          </Grid>
          <Grid item>
          <IconButton
              onClick={closeHandler}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </Typography>
    </div>
    <Divider />
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="flex-start" >
            <Box>
              <Typography variant='button' fontWeight='700' onClick={handleAddProperty} className={drillFluid.details.loading || properties?.length ? classes.disableIcon : ''}>+ Add</Typography>
            </Box>
            {/* TODO if adding more records handle primary records modal */}
            <Box>
              <label className={classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }}
              onChange={uploadCSVFile}
              />
            </Box>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={drillFluid.details.loading}
            getRowId={(row) => row._id}
            columns={columns(renderCell, renderValueCell, renderActions, drillFluid?.details?.units, unit)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'50vh'}
            pageSize={100}
            sx={{
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              },
              '.MuiDataGrid-columnHeaderTitle': {
                lineHeight: 1.2,
                textAlign: 'left'
              }
            }}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
            onCellEditCommit={({ id, value, field }) => {
              updateData(id, field, value);
            }}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
          />
        </Grid>
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={handleDeleteDrillFluid}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
        <UnsavedChangesModal />
      </Grid>
    </Dialog>
  );
};
DrillFluidDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  drillFluidData: PropTypes.any
};
DrillFluidDetails.defaultProps = {
  data: {}
};
export default DrillFluidDetails;
