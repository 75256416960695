/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CancelPresentationRounded } from '@mui/icons-material';
import styles from './styles';

const UnSavedChangesConfirmationModal = ({ open, handleClose, handleMainDialogClose }) => {
  const classes = styles();

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        fullWidth={true}
        className={classes.dialogModal}
      >
        <div className={classes.dialogTitle}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>Confirm Navigation</Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  <CancelPresentationRounded fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
          </Typography>
        </div>
        <Divider />
        <DialogContent style={{ marginTop: '2px' }}>
          <DialogContentText align='center' style={{ marginLeft: '-0.7rem' }} color={'black'} fontSize={'18px'} fontWeight='bold' id="alert-dialog-description">
             Changes will be lost if you continue <br/><br/>
             Leave this page ?
          </DialogContentText>
        </DialogContent>
        <Grid container item justifyContent='end' marginBottom='1rem' marginLeft='-10px'>
          <DialogActions>
            <Button
              style={{ backgroundColor: '#444', width: '100px' }}
              onClick={() => {
                handleClose(false);
                handleMainDialogClose();
              }}
            >
              Yes
            </Button>
            <Button
              style={{ backgroundColor: '#444', width: '80px' }}
              onClick={() => {
                handleClose(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

UnSavedChangesConfirmationModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  handleMainDialogClose: PropTypes.any
};

export default UnSavedChangesConfirmationModal;
