/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Box, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import Datagrid from '../../Datagrid';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import SelectEditInputTextField from '../../SelectEditInputTextField';
import { createMudPumps, deleteMudPumps, updateMudPumps } from '../../../../store/actions/hydraulics';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import SelectEditValueTypes from '../../SelectEditValueTypes';
import DeleteConfirmationModal from '../../../deleteConfirmationModal';
import useUnsavedChanges from '../../../../hooks/useUnsavedChanges';
import useUnitConversion from '../../../../hooks/useUnitConversion';
import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const MudPumpModel = ({
  onCloseClick,
  selectedJob,
  isOpen,
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { mudPumps } = useSelector((state) => state.hydraulics);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const { unit, convertUnitSystem } = useUnitConversion();
  const {
    unSavedExists,
    handleClose,
    UnsavedChangesModal
  } = useUnsavedChanges(properties, onCloseClick);

  useEffect(() => {
    if (!mudPumps.loading) {
      const details = mudPumps.data.map((details) => {
        return {
          ...details,
          spm: details?.spm ?? 0
        };
      });
      setProperties(details);
    }
    return () => {
      setProperties([]);
    };
  }, [mudPumps.data]);

  useEffect(() => {
    if (properties[0]?.liner_size === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'liner_size', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'liner_size');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      pumpType: 'Triplex',
      liner_size: '',
      stroke_length: '',
      efficiency: '',
      spm: 0,
      bbl_stk: 0.0,
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true,
      isDirty: true,
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const calculateBBLStk = (row) => {
    const { liner_size = 0, stroke_length = 0, efficiency = 100, pumpType } = row;
    let bbl_stk;

    if (unit !== UNIT_SYSTEM.US) {
      let m3_stk = (2.3576 * Math.pow(10, -9)) * Math.pow(liner_size, 2) * stroke_length;
      m3_stk *= (efficiency / 100);
      bbl_stk = m3_stk;
    } else {
      bbl_stk = (0.000243 * Math.pow(liner_size, 2) * stroke_length) * (efficiency / 100);
    }

    if (pumpType === 'Quad') {
      bbl_stk *= 4 / 3;
    }

    return bbl_stk.toFixed(6);
  };

  const updateData = (id, field, value) => {
    const numericValue = Number(value);
    let errorMessage = '';
    const min_linerSize = mudPumps?.units?.liner_size.min;
    const max_linerSize = mudPumps?.units?.liner_size.max;
    const min_stroke_length = mudPumps?.units?.stroke_length.min;
    const max_stroke_length = mudPumps?.units?.stroke_length.max;
    const min_efficiency = mudPumps?.units?.efficiency.min;
    const max_efficiency = mudPumps?.units?.efficiency.max;

    if (field === 'liner_size' && (isNaN(numericValue) || numericValue < min_linerSize || numericValue > max_linerSize)) {
      errorMessage = `Liner size should be a number between ${min_efficiency} and ${max_linerSize}`;
    } else if (field === 'stroke_length' && (isNaN(numericValue) || numericValue < min_stroke_length || numericValue > max_stroke_length)) {
      errorMessage = `Stroke length should be a number between ${min_stroke_length} and ${max_stroke_length}`;
    } else if (field === 'efficiency' && (isNaN(numericValue) || numericValue < min_efficiency || numericValue > max_efficiency)) {
      errorMessage = `Efficiency should be a number between ${min_efficiency} and ${max_efficiency}`;
    }

    if (errorMessage) {
      setInvalidRows(prev => ({ ...prev, [id]: true }));
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    } else {
      setInvalidRows(prev => ({ ...prev, [id]: false }));
      setProperties(prevProperties => {
        const updatedProperties = prevProperties.map(item => {
          if (item._id === id) {
            const isDirty = value !== item[field];
            const updatedItem = { ...item, [field]: value, isDirty };

            if (field === 'liner_size' || field === 'stroke_length' || field === 'efficiency' || field === 'pumpType') {
              updatedItem.bbl_stk = calculateBBLStk(updatedItem);
            }

            return updatedItem;
          }
          return item;
        });
        return updatedProperties;
      });
    }
  };

  const checkMudPumpValues = (row) => {
    const invalidFields = [];
    if (!row.bbl_stk || Number(row.bbl_stk) <= 0) {
      if (!row.liner_size && !row.stroke_length && !row.efficiency) {
        setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        dispatch(
          enqueueSnackbar(
            'Output (bbl/stk) must be greater than zero.',
            'error',
            new Date().getTime() + Math.random()
          )
        );
        return false;
      }
    }
    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (row.bbl_stk <= 0) {
      dispatch(
        enqueueSnackbar(
          'Pump output should be greater than zero.',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    if (row.spm < mudPumps?.units?.spm.min || row.spm > mudPumps?.units?.spm.max) {
      dispatch(
        enqueueSnackbar(
          `SPM should be between ${mudPumps?.units?.spm.min} and ${mudPumps?.units?.spm.max}.`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }

    if (checkMudPumpValues(row)) {
      const totalSPM = properties.reduce((sum, item) => sum + Number(item?.spm), 0);
      if (totalSPM === 0) {
        dispatch(
          enqueueSnackbar(
            'Pumps SPM is required for calculate displacement rates',
            'error',
            new Date().getTime() + Math.random()
          )
        );
        return;
      }
      const payload = {
        pumpType: row.pumpType,
        liner_size: unit !== UNIT_SYSTEM.US ? convertUnitSystem(Number(row.liner_size), mudPumps?.units?.liner_size?.conversionFactor) : Number(row.liner_size),
        stroke_length: unit !== UNIT_SYSTEM.US ? convertUnitSystem(Number(row.stroke_length), mudPumps?.units?.stroke_length?.conversionFactor) : Number(row.stroke_length),
        efficiency: unit !== UNIT_SYSTEM.US ? convertUnitSystem(Number(row.efficiency), mudPumps?.units?.efficiency?.conversionFactor) : Number(row.efficiency),
        bbl_stk: unit !== UNIT_SYSTEM.US ? convertUnitSystem(Number(row.bbl_stk), mudPumps?.units?.bbl_stk?.conversionFactor) : Number(row.bbl_stk),
        grid_id: row.grid_id,
        spm: Number(row?.spm)
      };
      if (row?.isNewRow) {
        dispatch(createMudPumps(payload, selectedJob?.wellsInfoId, selectedJob?._id));
      } else {
        dispatch(updateMudPumps(row, payload));
      }
    }
  };

  const handleDelete = (row) => {
    dispatch(deleteMudPumps(row?._id, row?.wellsInfoId));
    setDeleteAction(false);
    setDeleteRow({});
  };

  const renderValueCell = (params) => {
    return (
      <SelectEditInputTextField
        numberFormatOnly
        {...params}
        updateData={updateData}
      />
    );
  };

  const renderActionCell = ({ row }) => {
    const isRowInvalid = invalidRows[row._id];
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled= {!row.isDirty || isRowInvalid}
          onClick={() => {
            saveProperty(row);
          } }
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            // handleDelete(row);
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderTypeCell = (params) => {
    return (
      <SelectEditValueTypes
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={['Triplex', 'Quad']}
      />
    );
  };

  const closeHandler = () => {
    if (unSavedExists) {
      handleClose(false);
      return;
    }
    onCloseClick();
  };

  const isCellEditable = (params) => {
    if (params.field === 'bbl_stk') {
      const { liner_size, stroke_length, efficiency } = params.row;
      return !((liner_size > 0) || (stroke_length > 0) || (efficiency > 0));
    }
    return true;
  };

  return (
    <Dialog
    sx={{
      marginLeft: '-1%'
    }}
    open={isOpen}
    onClose={onCloseClick}
    className={classes.dialogModal}
    maxWidth={'lg'}
    fullWidth={true}
  >
        <div className={classes.dialogTitle}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Mud Pumps'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={closeHandler}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </Typography>
    </div>
    <Divider />
    <Grid container>
    <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
           <Box>
              <Typography
                variant='button'
                fontWeight='700'
                className={mudPumps.loading || properties[0]?.isNewRow ? classes.disableIcon : ''}
                onClick={handleAddProperty}>
                + Add
              </Typography>
            </Box>
        </Grid>
      <Grid item xs={12}>
      <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={mudPumps.loading}
            getRowId={(row) => row._id}
            columns={columns(renderValueCell, renderActionCell, renderTypeCell, mudPumps?.units, unit)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'50vh'}
            sx={{
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              }
            }}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            pageSize={100}
            isCellEditable={isCellEditable}
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
      </Grid>
    </Grid>
    {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={handleDelete}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
        <UnsavedChangesModal />
  </Dialog>
  );
};
MudPumpModel.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
MudPumpModel.defaultProps = {
  selectedJob: {}
};

export default MudPumpModel;
