import { REDUX_ACTIONS } from '../../shared/config/constants';
const initialState = {
  loading: false,
  deleting: false,
  data: [],
  units: {},
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0
};
const wellsWindowReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.WELLS_WINDOW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        units: { ...action.payload.units },
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.WELLS_WINDOW_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.WELLS_WINDOW_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.WELLS_WINDOW_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
export default wellsWindowReducer;
