import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  flexAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '5px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  vendorContainer: {
    alignItems: 'center',
    marginRight: '16px'
  },
  runActionContainer: {
    marginLeft: '-8px'
  },
  dateSwiftContainer: {
    marginLeft: '-15px'
  },
  aspectRatioContainer: {
    marginLeft: '-10px',
  },
  piechartContainer: {
    marginLeft: '-17px',
  },
  dateTimePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  divisionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px'
  },
  divisionText: {
    color: '#000',
    fontWeight: 'bold',
    marginRight: '14px'
  }
}));

export default styles;
