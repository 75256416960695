import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  data: [],
  errorMessage: '',
};

const favoritePartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FAVORITE_PARTS_DATA_LOADING:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case REDUX_ACTIONS.FAVORITE_PARTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
      };
    case REDUX_ACTIONS.FAVORITE_PARTS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        errorMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default favoritePartsReducer;
