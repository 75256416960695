/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CONTROLS_TYPE, PAGES_DATA } from '../../../utils/constants';
import DateRangeSlider from './DateRangeSlider';
import ShoppingCart from './ShoppingCart';
import DateRageSearch from './DateRageSearch';
import EpicorControls from './EpicorControls/index';
import SearchBox from './SearchBox';
import AlarmsControls from './AlarmsControls';
import MainMenu from './MainMenu';
import PageTitle from './PageTitle';
import EventTag from './EventTag';
import AspectRatio from './AspectRatio';
import TransactionControls from './TransactionControls';
import AlarmsHistoryControls from './AlarmsHistoryControls';
import TruFlowControls from './TruFlowControls';
import SpeedChart from './SpeedChart';
import DownloadOrderPDF from './DownloadOrderPDF';
import SyncMobileDevices from './SyncMobileDevices';
import UsersControls from './UsersControls';
import CompanyControls from './CompanyControls';
import WellsControls from './WellsControls';
import InventoryView from './InventoryViewControl';
import { isMobile } from '../../../utils/isMobile';
import MarketplaceTransactionControls from './MarketplaceTransactionControls';
import AuditLogControls from './AuditLogControls';
import UserName from './UserName';
import { isMobileOnly } from 'react-device-detect';
import AlarmNotification from './AlarmNotification';
import RigsControls from './RigsControls';
import AssetTypesControls from './AssetTypesControls';
import EnverusWellsControls from './EnverusWellsControls';
import CheckInsControls from './CheckInsControls';
import TransactionHistoryKPIControls from './TransactionHistoryKpiControls';
import JobsControls from './JobsControls';
import CheckInsKPIControls from './CheckInsKPIControls';
import AssetDataMobileControls from './AssetDataMobileControls';
import FieldboxesKPIControls from './FieldboxesKPIControls';
import MarketplaceLogControls from './MarketplaceLogControls';
import CheckInsDrillControls from './CheckInsDrillControls';
import DbwVsTargetControls from './DbwVsTargetControls';
import SpendControls from './SpendControls';
import SendBinReminders from './SendBinReminders';
import HydraulicsLogControls from './HydraulicsLogControls';

const NavActions = ({ currentPage, isMobile }) => {
  const mobile = isMobileOnly;
  return (
    <>
        {
            currentPage.controls?.includes(CONTROLS_TYPE.APPBAR_SUBTITLE) && !mobile ? <PageTitle /> : null
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.ALARM_NOTIFICATION) && !mobile ? <AlarmNotification/> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.PRINT_ORDER) ? <DownloadOrderPDF /> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.COMPANY_CONTROLS) ? <CompanyControls /> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.EPICOR_CONTROLS) &&
            <EpicorControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.SEARCH_BOX) &&
            <SearchBox />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.DATE_PRESETS_SLIDER) && !mobile
              ? <DateRangeSlider />
              : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.SHOPPING_CART) &&
            <ShoppingCart />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.EVENT_TAG) && !mobile
              ? <EventTag />
              : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.SYNC_MOBILE_DEVICES) &&
            <SyncMobileDevices />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.SEND_BIN_REMINDERS) &&
            <SendBinReminders />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.SPEND_CONTROLS) && !mobile
            ? <SpendControls />
            : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.ASPECT_RATIO) && !mobile
              ? <AspectRatio />
              : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.SPEED_CHART) && !mobile
              ? <SpeedChart />
              : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.ALARMS_CONTROLS) ? <AlarmsControls /> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.DATE_RANGE_SEARCH) &&
            <DateRageSearch />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.TRANSACTION_CONTROLS) &&
            <TransactionControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.MARKETPLACE_TRANSACTION_CONTROLS) &&
            <MarketplaceTransactionControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.ALARM_HISTORY_CONTROLS) &&
            <AlarmsHistoryControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.TRU_FLOW_CONTROLS) &&
            <TruFlowControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.USERS_CONTROLS) ? <UsersControls /> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.INVENTORY_VIEW) && !isMobile() && <InventoryView/>
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.WELLS_CONTROLS) &&
            <WellsControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.RIGS_CONTROLS) &&
            <RigsControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.ENVERUS_WELLS_CONTROLS) &&
            <EnverusWellsControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.ASSETTYPE_CONTROLS) &&
            <AssetTypesControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.AUDITLOG_CONTROLS) && <AuditLogControls />
        }
         {
          currentPage.controls?.includes(CONTROLS_TYPE.HYDRAULICSLOG_CONTROLS) && <HydraulicsLogControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.MARKETPLACELOGS_CONTROLS) && <MarketplaceLogControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.CHECK_INS_CONTROLS) &&
          <CheckInsControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.CHECK_INS_DRILL_CONTROLS) &&
          <CheckInsDrillControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.TRANSACTION_HISTORY_KPI) && <TransactionHistoryKPIControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.DBW_VS_TARGET_CONTROLS) &&
          <DbwVsTargetControls />
        }
         {
          currentPage.controls?.includes(CONTROLS_TYPE.CHECK_INS_KPI) && <CheckInsKPIControls />
        }
        {
          currentPage.controls?.includes(CONTROLS_TYPE.FIELDBOXES_KPI_CONTROLS) && <FieldboxesKPIControls />
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.JOBS_CONTROLS) ? <JobsControls /> : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.EVENT_TAG) && mobile
              ? <AssetDataMobileControls />
              : null
        }
        {
            currentPage.controls?.includes(CONTROLS_TYPE.APPBAR_USERNAME) ? <UserName currentPage={currentPage} /> : null
        }
    </>
  );
};

const ControlPanel = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState({});

  const findPageByPath = (pages, path) => {
    for (const page of pages) {
      if (page.link === path) {
        return page;
      }
      if (page.submenu) {
        const subPage = findPageByPath(page.submenu, path);
        if (subPage) {
          return subPage;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const _currentPage = findPageByPath(PAGES_DATA, location.pathname);
    if (_currentPage) {
      setCurrentPage(_currentPage);
    } else {
      setCurrentPage({});
    }
  }, [location]);

  /* prettier-ignore */
  return currentPage
    ? (
      <>
        {!isMobile()
          ? (
            <>
              <MainMenu />
              <NavActions currentPage={currentPage} isMobile={isMobile} />
            </>
            )
          : (
            <Grid flexDirection={'column'} justifyContent={'space-between'} sx={{ margin: 0, width: '100%' }}>
              <Grid item sm={12} sx={{ padding: '0px 14px' }}>
                <MainMenu isMobile={isMobile} />
              </Grid>
              <Grid item sm={12} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ backgroundColor: '#FFF', padding: '14px', minHeight: '56px' }}>
                <NavActions currentPage={currentPage} isMobile={isMobile} />
              </Grid>
            </Grid>
            )}
      </>
      )
    : null;
};

ControlPanel.propTypes = {
  isMobile: PropTypes.bool,
};

ControlPanel.defaultProps = {
  isMobile: false,
};

export default ControlPanel;
