/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import Datagrid from '../../Datagrid';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, DeleteRounded, DirectionsRunRounded, HeatPump, LogoutOutlined, SaveRounded } from '@mui/icons-material';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { createMudDisplacement, deleteMudDisplacement, runDisplacement, updateMudDisplacement } from '../../../../store/actions/hydraulics';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import DeleteConfirmationModal from '../../../deleteConfirmationModal';
import useUnsavedChanges from '../../../../hooks/useUnsavedChanges';
import SelectDrillDetails from '../../SelectDrillDetails';
import MudPumpModel from '../MudPumpModal';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const MudDisplacementModal = ({
  onCloseClick,
  selectedJob,
  isOpen,
  rowData
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { mudDisplacement, drillDetails, mudPumps } = useSelector((state) => state.hydraulics);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [deleteAction, setDeleteAction] = useState(false);
  const [showMudPumps, setShowMudPumps] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const {
    unSavedExists,
    handleClose,
    UnsavedChangesModal
  } = useUnsavedChanges(properties, onCloseClick);

  useEffect(() => {
    if (!mudDisplacement.loading && !drillDetails?.loading) {
      const { drillFluids = [] } = drillDetails?.hydraulicsDrillDetails || {};
      const { data: mudDetails = [] } = mudDisplacement;
      if (mudDetails.length > 0) {
        const updatedMudDetails = mudDetails.map(detail => {
          const drillFluidFrom = drillFluids.find(item => item._id === detail.fromFluidId);
          const drillFluidTo = drillFluids.find(item => item._id === detail.toFluidId);
          const checkRunFluidIdDisplacementFromFluidId = mudDisplacement?.data[0]?.fromFluidId === rowData?.run_details?.drillFluidsId;
          const updatedDetail = {
            ...detail,
            fromFluidId: drillFluidFrom
              ? `${drillFluidFrom.name.trim()} @ ${drillFluidFrom.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}`
              : 'N/A',
            toFluidId: drillFluidTo
              ? `${drillFluidTo.name.trim()} @ ${drillFluidTo.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}`
              : `${drillFluids[0].name.trim()} @ ${drillFluids[0].MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}`,
          };
          if (!drillFluidFrom || !drillFluidTo) {
            updatedDetail.isDirty = true;
          }
          if (!checkRunFluidIdDisplacementFromFluidId || !drillFluidFrom) {
            dispatch(
              enqueueSnackbar(
                'Please check mismatch in From Mud Fluid with Run details DrillFluid ',
                'error',
                new Date().getTime() + Math.random()
              )
            );
          }
          return updatedDetail;
        });
        setProperties(updatedMudDetails);
      } else {
        handleAddProperty();
      }
    }
  }, [mudDisplacement.data]);

  useEffect(() => {
    if (properties[0]?.toFluidId === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'toFluidId', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'toFluidId');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddProperty = () => {
    const { drillFluids = [] } = drillDetails?.hydraulicsDrillDetails || {};
    const drillFluidFrom = drillFluids.find(item => item._id === rowData?.run_details?.drillFluidsId);
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      fromFluidId: `${drillFluidFrom.name.trim()} @ ${drillFluidFrom.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}`,
      toFluidId: '',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true,
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          const isDirty = value !== item[field];
          return {
            ...item,
            [field]: (value),
            isDirty
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const checkMudDisplacementValues = (row) => {
    const invalidFields = [];
    if (!row.fromFluidId) invalidFields.push('From Mud Fluid');
    if (!row.toFluidId) invalidFields.push('To MudFluid');

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const checkFromToFluidId = (row) => {
    const fromId = drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}` === row?.fromFluidId)[0]._id;
    const toId = drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}` === row?.toFluidId)[0]._id;
    if (fromId === toId) {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'From Mud Fluid and To Mud Fluid Should not be the same',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    }
  };

  const checkFlowRate = (row) => {
    if (rowData?.run_details?.flow_in >= 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'Flow rate cannot be negative number',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const checkPumpOutPut = (row) => {
    if (mudPumps?.data[0]?.bbl_stk > 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'Mud Pump Output must be greater than 0.0',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (checkMudDisplacementValues(row) && checkFromToFluidId(row) && checkFlowRate(row) && checkPumpOutPut(row)) {
      const payload = {
        fromFluidId: drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}` === row?.fromFluidId)[0]._id,
        toFluidId: drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD.toFixed(drillDetails?.units?.MD?.decimals)}${drillDetails?.units?.MD?.label}` === row?.toFluidId)[0]._id,
        wellsInfoId: rowData?.wellsInfoId,
        runId: rowData?._id,
        pumps_output: mudPumps?.data[0]?.bbl_stk
      };
      if (row?.isNewRow) {
        dispatch(createMudDisplacement(payload));
      } else {
        dispatch(updateMudDisplacement(row, payload));
      }
    }
  };

  const handleDelete = (row) => {
    const body = {
      _id: row?._id,
      runId: rowData?._id,
      wellsInfoId: rowData?.wellsInfoId,
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          }
    };
    dispatch(deleteMudDisplacement(body));
    setDeleteAction(false);
    setDeleteRow({});
    setProperties([]);
  };

  const runMudDisplacement = (hydraulicsRunID) => {
    const body = {
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          },
      runId: hydraulicsRunID
    };
    const totalSpm = mudPumps?.data.reduce((sum, item) => sum + item?.spm, 0);
    if (totalSpm > 0) {
      dispatch(runDisplacement(body, () => {
        onCloseClick();
      }));
    } else {
      dispatch(
        enqueueSnackbar(
          'Pump SPM’s are required to calculate displacement rate',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    }
  };

  const renderMudPumpsAction = () => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          sx={{
            color: mudPumps?.data?.length ? 'green' : 'default'
          }}
          onClick={() => {
            setShowMudPumps(true);
          }}
        >
          <HeatPump />
        </IconButton>
      </Grid>
    );
  };

  const renderActionCell = ({ row }) => {
    const { drillFluids = [] } = drillDetails?.hydraulicsDrillDetails || {};
    const checkForDeletedDrillFluidIdFrom = drillFluids.some(drillString => drillString._id === mudDisplacement?.data[0]?.fromFluidId);
    const checkForDeletedDrillFluidIdTo = drillFluids.some(drillString => drillString._id === mudDisplacement?.data[0]?.toFluidId);
    const checkRunFluidIdDisplacementFromFluidId = mudDisplacement?.data[0]?.fromFluidId === rowData?.run_details?.drillFluidsId;
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={!row.isDirty || !mudPumps?.data?.length}
          onClick={() => {
            saveProperty(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
          <DeleteRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow || !checkForDeletedDrillFluidIdTo || !checkForDeletedDrillFluidIdFrom || !checkRunFluidIdDisplacementFromFluidId}
          onClick={() => {
            runMudDisplacement(rowData?._id);
          }}
        >
          <DirectionsRunRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderDrillFluidCell = (params) => {
    return (
      <SelectDrillDetails
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={drillDetails?.hydraulicsDrillDetails?.drillFluids}
        label={drillDetails?.units?.MD?.label}
        decimals={drillDetails?.units?.MD?.decimals}
      />
    );
  };

  const closeHandler = () => {
    if (unSavedExists) {
      handleClose(false);
      return;
    }
    onCloseClick();
  };

  if (apiRef.current === null) apiRef.current = {};

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onCloseClick();
        setInvalidRows({});
      }}
      maxWidth={'lg'}
      fullWidth={true}
      className={classes.dialogModal}
    >
      <div className={classes.dialogTitle}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {'Rollover'}
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  runMudDisplacement(rowData?._id);
                }}
                disabled={properties[0]?.isNewRow || !properties.length || mudDisplacement.loading}
              >
                <LogoutOutlined fontSize='large' />
              </IconButton>
              <IconButton
                onClick={() => {
                  closeHandler();
                  setInvalidRows({});
                }}
              >
                <CancelPresentationRounded fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={mudDisplacement.loading}
            getRowId={(row) => row._id}
            columns={columns(renderActionCell, renderDrillFluidCell, renderMudPumpsAction)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'400px'}
            sx={{
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              },
              '& .MuiDataGrid-footerContainer': {
                minHeight: '0px'
              },
            }}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            hideFooterPagination
            hideFooterSelectedRowCount
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
      </Grid>
      {
        showMudPumps
          ? <MudPumpModel
            selectedJob={selectedJob}
            isOpen={showMudPumps}
            onCloseClick={() => {
              setShowMudPumps(false);
            }}
          />
          : null
      }
      {
        deleteAction && <DeleteConfirmationModal
          open={deleteAction}
          handleDelete={handleDelete}
          handleClose={setDeleteAction}
          deleteRow={deleteRow}
        />
      }
      <UnsavedChangesModal />
    </Dialog>
  );
};
MudDisplacementModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.object
};
MudDisplacementModal.defaultProps = {
  selectedJob: {}
};

export default MudDisplacementModal;
