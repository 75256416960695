import { Box, Typography } from '@mui/material';
import { USER_TYPE } from '../../../utils/constants';

const columns = (renderCell, renderOrdersCell, renderQuotesCell, userType, renderActions, renderCompaniesCell, renderAccessLevelCell, emailEditable, renderSellersCell, renderInventoryCell, renderValueCell, renderSpecialInstructionsCell, renderPersonnelCell, renderSpendCell, renderFavoritesCell) => [
  {
    field: 'firstName',
    headerName: 'Name',
    width: 300,
    renderCell,
    renderEditCell: renderValueCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'emailAddress',
    headerName: 'Email',
    width: 400,
    renderCell,
    renderEditCell: renderValueCell,
    editable: (userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN) && emailEditable,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN ? false : true,
    renderCell,
    renderEditCell: renderValueCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'companyName',
    headerName: 'Company',
    width: 350,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN,
    headerAlign: 'center',
    align: 'center',
    renderEditCell: renderCompaniesCell
  },
  {
    field: 'type',
    headerName: 'Access Level',
    width: 300,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN,
    headerAlign: 'center',
    align: 'center',
    renderEditCell: renderAccessLevelCell
  },
  {
    field: 'orders',
    headerName: 'Orders',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderOrdersCell,
  },
  {
    field: 'quotes',
    headerName: 'Quotes',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderQuotesCell,
  },
  {
    field: 'sellers',
    headerName: 'Sellers',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderSellersCell,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN ? false : true,
  },
  {
    field: 'Inventory',
    headerName: 'Inventory',
    width: 100,
    renderCell: renderInventoryCell,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN ? false : true,
    headerAlign: 'center',
  },
  {
    field: 'specialInstructions',
    width: 100,
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Special
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Instructions
      </Typography>
      </Box>
    ),
    // eslint-disable-next-line no-unneeded-ternary
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER ? false : true,
    renderCell: renderSpecialInstructionsCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'personnel',
    headerName: 'Personnel',
    width: 100,
    // eslint-disable-next-line no-unneeded-ternary
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER ? false : true,
    renderCell: renderPersonnelCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'spend',
    headerName: 'Spend',
    width: 100,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN   ? false : true,
    renderCell: renderSpendCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'isPartsFavorites',
    headerName: 'Favorites',
    width: 100,
    // eslint-disable-next-line
     hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN  ? false : true,
    renderCell: renderFavoritesCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    // eslint-disable-next-line
    hide: (userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN)  ? false : true,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export default columns;
