export const modifyMetricData = (data, units, currentUnit) => {
  if (!Array.isArray(data)) return [];

  return data.map((item) => {
    if (!item || typeof item !== 'object') return item;

    const modifiedItem = { ...item };

    if (currentUnit !== 'US') {
      Object.keys(modifiedItem).forEach((key) => {
        const unit = units?.[key];
        if (unit && modifiedItem[key] !== undefined && unit?.conversionFactor) {
          const conversionFactor = Number(unit.conversionFactor || 1);
          const decimals = unit.decimals || 0;

          modifiedItem[key] = parseFloat((modifiedItem[key] * conversionFactor).toFixed(decimals));
        }
      });
    }

    return modifiedItem;
  });
};

export const modifyMetricDataArray = (data, units, currentUnit) => {
  if (!Array.isArray(data)) return [];

  return data.map((item) => {
    if (!item || typeof item !== 'object') return item;

    Object.keys(item).forEach((key) => {
      const unit = units?.[key];

      if (currentUnit !== 'US' && unit?.conversionFactor) {
        const conversionFactor = Number(unit.conversionFactor || 1);
        const decimals = unit.decimals || 0;

        if (typeof item[key] === 'number') {
          item[key] = parseFloat((item[key] * conversionFactor).toFixed(decimals));
        }

        if (Array.isArray(item[key])) {
          item[key] = item[key].map((subItem) => {
            if (typeof subItem === 'number') {
              return parseFloat((subItem * conversionFactor).toFixed(decimals));
            }
            return subItem;
          });
        }
      }
    });

    return item;
  });
};
