import { USER_TYPE } from '../../../utils/constants';
import { renderCustomPrice } from '../../../utils/renderCustomPrice';
const columns = (renderCell, userType, renderDescriptionCell) => [
  {
    field: 'Classification',
    headerName: 'Classification',
    hide: true,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Image',
    headerName: 'Image',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'PN',
    headerName: 'PN',
    width: 120,
    hide: true,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Category',
    headerName: 'Category',
    width: 140,
    renderCell,
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 250,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell
  },
  {
    field: 'Size',
    headerName: 'Size',
    width: 120,
    hide: false,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell
  },
  {
    field: 'Price',
    headerName: 'Price',
    width: 120,
    hide: false,
    renderCell: renderCustomPrice,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Description',
    headerName: 'Description',
    hide: false,
    // eslint-disable-next-line
    renderCell: userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_RIG || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN  ? renderDescriptionCell : renderCell,
    width: 550,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'VendorName',
    headerName: 'Seller',
    hide: false,
    width: 300,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell
  },
];

export default columns;
