import { UNIT_SYSTEM } from '../../../shared/config/hydraulics_constants';

const columns = (renderCell, renderActions, renderValueCell, drillStringDetailsUnit, currentUnit) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'od',
    headerName: `OD (${drillStringDetailsUnit?.od?.label})`,
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillStringDetailsUnit?.od?.decimals));
    },
  },
  {
    field: 'id',
    headerName: `ID (${drillStringDetailsUnit?.id?.label})`,
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillStringDetailsUnit?.id?.decimals));
    },
  },
  {
    field: 'tj_od',
    headerName: `TJ OD (${drillStringDetailsUnit?.tj_od?.label})`,
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillStringDetailsUnit?.tj_od?.decimals));
    },
  },
  {
    field: 'tj_id',
    headerName: `TJ ID (${drillStringDetailsUnit?.tj_id?.label})`,
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillStringDetailsUnit?.tj_id?.decimals));
    },
  },
  {
    field: 'len',
    headerName: `LENGTH (${drillStringDetailsUnit?.len?.label})`,
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillStringDetailsUnit?.len?.decimals));
    },
  },
  {
    field: 'tj_len%',
    headerName: 'TJ LENGTH (%)',
    width: 170,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 140,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => renderActions({ row: params.row, index: params.api.getRowIndex(params.id) }),
  },
];
export default columns;
